
import Base from "@/views/layout/Base.vue";
import { fund, yearDic } from "./data.js";
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import request from "@/utils/request";
export default defineComponent({
  components: {
    Base,
  },
  setup() {
    const isLoading = ref(false);
    const fundFilter = ref();


    const provDoPercent = ref(0);
    const search = () => {
      isLoading.value = true;

      request.get(`/query/yeartable`).then((p) => {
        fundFilter.value = p
        isLoading.value = false
        provDoPercent.value = p[0].fundPercent
      });

    };

    const star = (item) => {
      if (item.fundPercent - provDoPercent.value > 5)
        return 3
      if (item.fundPercent - provDoPercent.value < 5)
        return 1

      return 2;
    }
    onMounted(() => {
      search();
      watch(filter, () => {
        search();
      });
    });

    const filter = reactive({
      year: "全部",
    });
    return {
      star,
      provDoPercent,
      isLoading,
      yearDic,
      filter,
      fundFilter,
    };
  },
});
