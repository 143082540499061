<template>
  <Base titleIcon="el-icon-s-claim">
  <el-space wrap style="margin-bottom: 12px;margin:auto;" alignment="start">
    <el-tabs tab-position="left" v-model="filter.year">
      <el-tab-pane label="全部" name="全部">
      </el-tab-pane>
      <el-tab-pane v-for="item in yearDic" :key="item" :label="item" :name="item">
      </el-tab-pane>
    </el-tabs>
    <el-table :data="fundFilter" v-loading="isLoading">
      <el-table-column label="" prop="name"></el-table-column>
      <el-table-column label="上报国保计划书项目数量" prop="nationSetupCount">

      </el-table-column>
      <el-table-column label="批复国保计划书数量" prop="nationSetupPCount">

      </el-table-column>
      <el-table-column label="上报省保计划书项目数量" prop="provSetupCount">

      </el-table-column>
      <el-table-column label="批复省保计划书项目数量" prop="provSetupPCount">

      </el-table-column>
      <el-table-column label="方案批复数量" prop="planPCount">

      </el-table-column>
      <!-- <el-table-column label="申请国家资金项目金额" prop="申请国家资金项目金额">
          
        </el-table-column> -->
      <el-table-column label="安排国家资金项目金额" prop="allocateMoneyNation">

      </el-table-column>
      <el-table-column label="申请省级资金项目金额" prop="applyProvMoney">

      </el-table-column>
      <el-table-column label="安排省级资金项目金额" prop="allocateMoneyProv">

      </el-table-column>
      <el-table-column label="资金执行率" prop="fundPercent">
        <template #default="{ row: item }">
          {{ item.fundPercent.toFixed(2) }} %
        </template>
      </el-table-column>
      <el-table-column label="资金结余率" prop="fundLeftPercent">
        <template #default="{ row: item }">
          {{ item.fundPercent > 0 ? (100 - item.fundPercent).toFixed(2) : 0 }} %
        </template>
      </el-table-column>
      <el-table-column label="星级" width="120">
        <template #default="{ row: item }">

          <el-rate :modelValue="star(item)" :max="3" />
        </template>
      </el-table-column>
    </el-table>
  </el-space>
  </Base>
</template>

<script lang="ts">
import Base from "@/views/layout/Base.vue";
import { fund, yearDic } from "./data.js";
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import request from "@/utils/request";
export default defineComponent({
  components: {
    Base,
  },
  setup() {
    const isLoading = ref(false);
    const fundFilter = ref();


    const provDoPercent = ref(0);
    const search = () => {
      isLoading.value = true;

      request.get(`/query/yeartable`).then((p) => {
        fundFilter.value = p
        isLoading.value = false
        provDoPercent.value = p[0].fundPercent
      });

    };

    const star = (item) => {
      if (item.fundPercent - provDoPercent.value > 5)
        return 3
      if (item.fundPercent - provDoPercent.value < 5)
        return 1

      return 2;
    }
    onMounted(() => {
      search();
      watch(filter, () => {
        search();
      });
    });

    const filter = reactive({
      year: "全部",
    });
    return {
      star,
      provDoPercent,
      isLoading,
      yearDic,
      filter,
      fundFilter,
    };
  },
});
</script>

<style scoped></style>